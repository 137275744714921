import React from 'react';
import ReactDOM from 'react-dom';
import CookieConsent from 'react-cookie-consent';

const App = () => {
  return (
    <CookieConsent
      location='bottom'
      cookieName='cookieConsent'
      style={{ background: '#2B373B' }}
      buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
    >
      This website uses cookies to enhance your user experience.
    </CookieConsent>
  );
};

ReactDOM.render(<App />, document.querySelector('#div_cookie'));
